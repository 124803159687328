import React from 'react';
import '../styles/LinksSection.css';

const LinksSection = () => {
  return (
    <section className="links-section">
      <input type="text" placeholder="Buy Link" />
      <input type="text" placeholder="Chart Link" />
      <input type="text" placeholder="Social Media Link" />
    </section>
  );
};

export default LinksSection;