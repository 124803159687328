import React from 'react';
import Header from './components/Header';
import TextSection from './components/TextSection';
import LinksSection from './components/LinksSection';
import './App.css';

function App() {
  return (
    <div className="app">
      <Header />
      <TextSection />
      <LinksSection />
    </div>
  );
}

export default App;