import React from 'react';
import '../styles/TextSection.css';

const TextSection = () => {
  return (
    <section className="text-section">
      <div className="text-area">
        <textarea placeholder="Enter main description" />
      </div>
      <div className="text-area">
        <textarea placeholder="Enter tokenomics" />
      </div>
      <div className="text-area">
        <textarea placeholder="Enter roadmap" />
      </div>
    </section>
  );
};

export default TextSection;